import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { memo, ReactNode, useMemo } from "react";
import Image from "next/image";
import formatImage from "utils/formatImage";
import Text from "./Text";
import GallerySlider from "./GallerySlider";

type InnerTextCardProps = {
  photos: string[];
  title: string;
  className?: string;
  componentOnHover?: ReactNode;
};

const InnerTextCard: React.FC<InnerTextCardProps> = ({
  photos,
  className,
  title,
  componentOnHover,
}) => {
  const { other } = useTheme();
  
  const Body = useMemo(() => styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height:230px;
    width:100%;
    gap: 30px;
    border-radius: ${other.borderRadius};
    position: relative;
    & img {
      transform: scale(1);
      object-fit: cover;
      transition-duration: 0.3s;
      image-rendering: high-quality;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      height: 100%;
      width: 100%;
    }
    &.withHover:hover {
      #inner-text-block {
        bottom: 70px;
        @media (max-width:425px){
          bottom:90px;
        }
      }
      #inner-hover-component {
        opacity: 1;
        top: 100%;
        transition: 0.4s ease all;
        transition-delay: 0.3s;
        pointer-events:none;
      }
    }
  `, [other.borderRadius]);

  const GliderBody = useMemo(() => styled.div`
    position:relative;
    height:100%;
    & > img {
      width:100%;
      object-fit:cover;
    }
  `, []);

  const TextBlock = useMemo(() => styled.div`
    position: absolute;
    display: flex;
    bottom: 20px;
    padding: 0 20px;
    left: 0px;
    width: 100%;
    word-break: break-all;
    pointer-events:none;
    gap: 10px;
    flex-direction: column;
    transition: 0.2s ease all;
  `, []);

  const ComponentOnHover = useMemo(() => styled.div`
    position: absolute;
    width: 90%;
    opacity: 0;

    top: 130%;
  `, []);

  const BlurDiv = useMemo(() => styled.div`
    position: absolute;
    width: 100%;
    height: 35%;
    bottom: 0;
    pointer-events:none;
    left: 0;
    background: rgb(46, 46, 46);
    background: linear-gradient(
      0deg,
      rgba(46, 46, 46, 1) 0%,
      rgba(106, 106, 106, 0.7455357142857143) 50%,
      rgba(223, 223, 223, 0) 100%
    );
  `, []);

  return (
    <Body className={`${className ? `${className}` : ""} ${componentOnHover ? "withHover" : ""}`}>
      <GliderBody className="relative w-full h-full slider-adaptive flex-shrink-0 border-r border-neutral-100 dark:border-neutral-800">
        {photos.length > 1 ? (
          <GallerySlider
            photos={photos}
          />
        ) : photos.length === 1 ? (
          <Image
            src={formatImage(photos[0], 600, 500, "c_pad") || ""}
            width={600}
            height={500}
            // onClick={() => handleCardClick(data)}
            className="mx-auto cursor-pointer"
            alt="img"
          />
        ) : (
          <img
            src="/images/defaultCar.png"
            // onClick={() => handleCardClick(data)}
            className="mx-auto cursor-pointer"
            alt="img"
          />
        )}
      </GliderBody>
      <BlurDiv />
      <TextBlock id="inner-text-block">
        <Text tag="p" variant="boldSecondary">
          {title}
        </Text>
        {componentOnHover && (
          <ComponentOnHover id="inner-hover-component">{componentOnHover}</ComponentOnHover>
        )}
      </TextBlock>
    </Body>
  );
};

export default memo(InnerTextCard);
