import { FC, memo, useMemo } from "react";

import { FormattedMessage } from "react-intl";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import Text from "./Text";
import messages from "../store/messages";

type CarInfoCardProps = {
  seats: number | string;
  fueltype: string;
  gearbox: string;
};

const CarInfoCard: FC<CarInfoCardProps> = ({ fueltype, gearbox, seats }) => {
  const { palette } = useTheme();

  const Body = useMemo(() => styled.div`
    margin-top: 5px;
    height: 50px;
    pointer-events: none;
    cursor: loading;
  `, []);
  
  const Items = useMemo(() => styled.div`
    display: flex;
    margin: 10px 0;
    gap: 10px;
    width: 100%;
  `, []);

  const Item = useMemo(() => styled.div`
    display: flex;
    gap: 5px;
    @media (max-width: 425px) {
      gap:0;
      flex-direction: column;
    }
    align-items: center;
    width: 30%;
  `, []);
  
  const Divider = useMemo(() => styled.div`
    width: 30%;
    height: 1px;
    background: ${palette.secondary.light};
  `, [palette.secondary.light]);
  
  return (
    <Body>
      <Divider />
      <Items>
        <Item>
          <Text tag="span" variant="normalBigSecondary">
            <i className="las la-user-friends" />
          </Text>
          <Text tag="span" variant="normalSmallSecondary">
            <FormattedMessage {...messages.seats} values={{ seats }} />
          </Text>
        </Item>
        <Item>
          <Text tag="span" variant="normalBigSecondary">
            <i className="las la-gas-pump" />
          </Text>
          <Text tag="span" variant="normalSmallSecondary">
            {fueltype}
          </Text>
        </Item>
        <Item>
          <Text tag="span" variant="normalBigSecondary">
            <i className="las la-cog" />
          </Text>
          <Text tag="span" variant="normalSmallSecondary">
            {gearbox}
          </Text>
        </Item>
      </Items>
    </Body>
  );
};
export default memo(CarInfoCard);
