import styled from "@emotion/styled";
import React, { ReactNode } from "react";
import { useTheme } from "@emotion/react";
import textConfig from "theme/text";
import Text from "./Text";

type Props = {
  children: ReactNode
  minWidth?: number
  type?: "button" | "submit"
  background?: "primary" | "transparent"
  textVariant?: keyof typeof textConfig
  onClick?: () => void
  id: string
};

const Button: React.FC<Props> = ({
  children,
  minWidth,
  type = "button",
  background = "primary",
  onClick,
  textVariant = "normalBigSecondary",
  id,
}) => {
  const { palette, sizes, other } = useTheme();
  const StyledButton = styled.button`
    color: white;
    height: ${sizes.buttonHeight};
    border: none;
    background: ${background === "primary" ? palette.additional.main : "transparent"};
    border: ${background === "transparent" && `1px solid ${palette.primary.dark}`};
    border-radius: ${other.borderRadius};
    ${minWidth && `min-width: ${minWidth}px;`}
    transition: 0.1s ease all;
    &:hover {
      ${background === "transparent" && `border: 1px solid ${palette.additional.dark};`}
      background: ${background === "primary" ? palette.additional.dark : palette.secondary.main};
    }
  `;

  return (
    <StyledButton type={type} onClick={onClick} id={id}>
      <Text variant={textVariant} tag="span">
        {children}
      </Text>
    </StyledButton>
  );
};

export default Button;
