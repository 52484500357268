// @flow strict
import styled from "@emotion/styled";
import { useMemo,
  useCallback, useEffect, useRef, useState, MouseEvent, TouchEvent, 
} from "react";

import NextPrev from "../NextPrev";
import formatImage from "../../utils/formatImage";

type Props = {
  photos: string[];
  onClick?:(event:MouseEvent<HTMLDivElement>)=>void;
}

function GallerySlider({ photos, onClick }: Props) {
  const galleryRef = useRef<HTMLDivElement>(null);

  const photosLength = photos.length;

  const [photoWidth, setPhotoWidth] = useState(0);
  const [currentScroll, setCurrentScroll] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [scrollIsChanging, setScrollIsChanging] = useState(false);

  const Body = useMemo(() => styled.div`
    width:100%;
    position:relative;
    overflow:hidden;
    height:100%;
    pointer-events: ${scrollIsChanging ? "none" : "auto"};
    &:hover{
      & #next-prev{
        opacity:100%;
      }
    }
  `, [scrollIsChanging]);
  
  const Overflow = useMemo(() => styled.div`
    display:flex;
    width:100%;
    max-width:100%;
    height:100%;
    scroll-behavior:smooth;
    position:relative;
    overflow-x:scroll;
    &::-webkit-scrollbar{
      display:none;
    }
    & > img{
      object-fit:cover;
      width:100%;
      height:100%;
      max-width:100%;
      aspect-ratio:16;
    }
    @media (max-width:768px){
      max-width:100%;
    }
  `, []);

  const NextPrevContainer = useMemo(() => styled.div`
    display:flex;
    top:50%;
    justify-content:space-between;
    transform:translateY(-50%);
    transition:0.2s ease-in-out all;
    box-sizing:border-box;
    pointer-events:none;
    transition-property:opacity;
    position:absolute;
    width:100%;
    padding:0 0.5rem;
    opacity:30%;
    @media(max-width:1280px){
      opacity:60%;
    }
  `, []);
  const onNext = useCallback(() => {
    const ref = galleryRef.current;

    if (ref) {
      setScrollIsChanging(true);
      if (currentIndex === photosLength - 1) {
        ref.scrollLeft = 0;
        setCurrentScroll(0);
        setCurrentIndex(0);
        setScrollIsChanging(false);
        return;
      }
      const scrollToWidth = currentScroll + photoWidth;
      ref.scrollLeft = currentScroll + photoWidth;
      setCurrentScroll(scrollToWidth);
      setCurrentIndex((prev) => prev + 1);
      setScrollIsChanging(false);
    }
  }, [currentIndex, currentScroll, photoWidth, photosLength]);

  const onPrev = useCallback((/* fromSwipe?:boolean */) => {
    const ref = galleryRef.current;
    if (ref) {
      setScrollIsChanging(true);
      if (currentIndex === 0) {
        const scrollToWidth = (photos.length - 1) * photoWidth;
        ref.scrollLeft = scrollToWidth;
        setCurrentScroll(scrollToWidth);
        setCurrentIndex(photos.length - 1);
        setScrollIsChanging(false);
        return;
      }
      const scrollToWidth = currentScroll - photoWidth;
      ref.scrollLeft = scrollToWidth;
      setCurrentScroll(scrollToWidth);
      setCurrentIndex((prev) => prev - 1);
      setScrollIsChanging(false);
    }
  }, [currentIndex, currentScroll, photoWidth, photos.length]);

  const onTouchStart = useCallback((event: TouchEvent<HTMLDivElement>) => {
    setTouchStartX(event.touches[0].clientX);
  }, []);

  const onTouchEnd = useCallback((event: TouchEvent<HTMLDivElement>) => {
    if (touchStartX - event.changedTouches[0].clientX > 0) {
      onNext();
    }
    if (touchStartX - event.changedTouches[0].clientX < 0) {
      onPrev();
    }
  }, [onNext, onPrev, touchStartX]);

  useEffect(() => {
    if (galleryRef.current) {
      setPhotoWidth(galleryRef.current.getBoundingClientRect().width);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [galleryRef]);

  return (
    <Body>
      <Overflow ref={galleryRef} onClick={onClick} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
        {photos.map((item) => <img src={formatImage(item, 300, 300, "c_pad")} alt="gallery-img" key={item} />)}
      </Overflow>
      {/* DOTS */}
      <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900" />
      {/* NAV */}
      <NextPrevContainer id="next-prev">
        <NextPrev onClickPrev={onPrev} onClickNext={onNext} />
      </NextPrevContainer>
    </Body>
  );
}
export default GallerySlider;
