import styled from "@emotion/styled";
import { FC, useMemo } from "react";

export interface NextPrevProps {
  className?: string;
  btnClassName?: string;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  onlyNext?: boolean;
  onlyPrev?: boolean;
}

const NextPrev: FC<NextPrevProps> = ({
  className = "",
  onClickNext,
  onClickPrev,
  btnClassName = "w-10 h-10",
  onlyNext = false,
  onlyPrev = false,
}) => {
  const Body = useMemo(() => styled.div`
    display:flex;
    align-items:center;
    width:100%;
    pointer-events:none;
    justify-content:space-between;
    height:0px;
    box-sizing:border-box;
    position:relative;
  `, []);
  const Button = useMemo(() => styled.button`
    background:white;
    position:relative;
    z-index:30;
    height:2rem;
    width:2rem;
    pointer-events:auto;
    border:1px solid #ebebeb;
    border-radius:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    transition:0.1s ease all;
    cursor:pointer;
    &:hover{
      border:1px solid #979797;
    }
    &:focus{
      outline:none;
    }
  `, []);
  return (
    <Body>
      {!onlyNext && (
      <Button
        type="button"
        onClick={onClickPrev}
      >
        {"<"}
      </Button>
      )}
      {!onlyPrev && (
      <Button
        type="button"
        onClick={onClickNext}
      >
        {">"}
      </Button>
      )}
    </Body>
  ); 
};

export default NextPrev;
