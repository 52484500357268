import { FC, memo, ReactNode, useMemo } from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

type BaseContainerProps = {
  centered?: boolean;
  children: ReactNode;
  withTopMargin?: boolean;
  marginTop?: string;
  direction?: "row" | "column";
};

const BaseContainer: FC<BaseContainerProps> = ({
  centered,
  children,
  direction = "column",
  withTopMargin,
  marginTop,
}) => {
  const { other } = useTheme();
  const Container = useMemo(
    () => styled.div`
      display: flex;
      flex-direction: ${direction};
      gap: 60px;
      margin-top: ${marginTop || (withTopMargin ? "10vh" : "15vh")};
      ${other.container};
      align-items: ${centered ? "center" : "flex-start"};
      ${direction === "row"
      && `@media (max-width:1024px){
      align-items:flex-start;
      flex-direction:column;
    }`}
      @media (max-width:1024px) {
        margin: 100px auto;
        margin-top: ${withTopMargin ? "50px" : "100px"};
      }
    `,
    [centered, direction, marginTop, other.container, withTopMargin],
  );

  return <Container>{children}</Container>;
};

export default memo(BaseContainer);
